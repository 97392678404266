/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $('#registerForm').submit(function(event) {

          $('.form-group').removeClass('has-error');
          $('.help-block').remove();

          var formData = {
            'prename' : $('input[name=staticPrename]').val(),
            'surname' : $('input[name=staticSurname]').val(),
            'prename2' : $('input[name=staticPrename2]').val(),
            'surname2' : $('input[name=staticSurname2]').val(),
            'prename3' : $('input[name=staticPrename3]').val(),
            'surname3' : $('input[name=staticSurname3]').val(),
            'prename4' : $('input[name=staticPrename4]').val(),
            'surname4' : $('input[name=staticSurname4]').val(),
            'email' : $('input[name=staticEmail]').val(),
            'phone' : $('input[name=staticPhone]').val(),
            'marriage' : $('input[name=marriage]').prop('checked'),
            'stay' : $('input[name=stay]').prop('checked'),
            'sat_sun_stay' : $('input[name=sat_sun_stay]').prop('checked'),
            'fri_sat_sun_stay' : $('input[name=fri_sat_sun_stay]').prop('checked'),
            'brand_car' : $('input[name=brand_car]').prop('checked'),
            'staticComingFrom' : $('input[name=staticComingFrom]').val(),
            'pickup' : $('input[name=pickup]').prop('checked'),
            'staticPickupNumber' : $('input[name=staticPickupNumber]').val(),
            'plane' : $('input[name=plane]').prop('checked'),
            'arrive_zurich'  : $('input[name=arrive_zurich]').val(),
            'arrive_time'  : $('input[name=arrive_time]').val(),
            'leave_zurich'  : $('input[name=arrive_zurich]').val(),
            'leave_time'  : $('input[name=leave_time]').val(),
            'shuttle'  : $('input[name=shuttle]').prop('checked')
          };

          $.ajax({
            type    : 'POST',
            url     : 'process.php',
            data    : formData,
            dataType  : 'json',
            encode    : true
          })
            .done(function(data) {

              if ( ! data.success) {

              } else {

                $('#registerForm').prepend('<div class="alert alert-success">' + data.message + '</div>');
                $('#registerForm').trigger("reset");

                var target = '#registration',
                $target = $(target);

                $('html, body').stop().animate({
                    'scrollTop': $target.offset().top - 1
                }, 1500, 'easeInOutExpo', function() {
                  
                });

              }
            })

            .fail(function(data) {

            });
          event.preventDefault();
          return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {

      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
